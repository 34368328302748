<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">SESSIONS</h2></div>
      <div class="box"><el-button @click="$onCommandParams('settings')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
    <el-table
      :data="dataSessions"
      style="width: 100%">
      <el-table-column
        label="Name">
            <template #default="scope">
              <el-input size="small"
                style="text-align:center"
                v-model="scope.row.name"></el-input>
            </template>
      </el-table-column>
      <el-table-column
        label="Description">
            <template #default="scope">
              <el-input size="small"
                type="textarea"
                style="text-align:center"
                v-model="scope.row.description"></el-input>
            </template>
      </el-table-column>

      <el-table-column>
        <template slot-scope="scope">

            <div v-if="scope.$index >= sessions.length">
                <el-button
                 @click.native.prevent="createSession(scope.row)" type="text" size="small">
                    Create
                </el-button>
            </div>
            <div class="flex gaps" v-else>
                <el-button @click.native.prevent="saveRow(scope.row)" type="text" size="small">
                    Save
                </el-button>
                <el-button @click.native.prevent="deleteRow(scope.row, scope.$index)" type="text" size="small">
                    Delete
                </el-button>
            </div>
        </template>
      </el-table-column>
    </el-table>
      <div class="flex justify-flex-end m-10">
        <el-button @click="addRow()" size="small">
            Add Session
        </el-button>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Sessions',

  data () {
    return {
      dataSessions: []
    }
  },

  computed: {
    ...mapState({
      sessions: state => state.session.sessions
    })
  },

  methods: {
    deleteRow (row, index) {
      this.$store.dispatch('removeSession', row._id)
        .then(() => {
          this.dataSessions.splice(index, 1)
        })
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Session removed successfully',
            type: 'success'
          })
        })
    },
    saveRow (row) {
      this.$store.dispatch('editSession', {
        id: row._id,
        content: row
      })
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Session edited successfully',
            type: 'success'
          })
        })
    },
    createSession (payload) {
      this.$store.dispatch('createSession', payload)
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Session created successfully',
            type: 'success'
          })
        })
    },
    addRow () {
      let newRow = {
        name: '',
        description: ''
      }
      this.dataSessions = [...this.dataSessions, newRow]
    }
  },
  mounted () {
    this.dataSessions = this.sessions.map((x) => Object.assign({}, x))
  }
}
</script>

<style lang="scss">
</style>
